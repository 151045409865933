import React from "react";
import { Box } from "@mui/material";
import "../style/LawFirmInfo.css";
import firstImage from "../images/about-MDP.webp";

const LawFirmInfoComponent = () => {
  return (
    <>
      <Box className="container-law" id="about-us">
        <Box className="text-section-law">
          <Box className="diamond-line-law"></Box>
          <Box className="text-content-law">
            <Box className="top-content-law">
              <h2>MDP Associates: Your Trusted Partner for Legal Success</h2>
              <p>
                MDP Associates - Advocates & Solicitors is a Mumbai based law
                firm built on a foundation of trust and legal skill. We serve a
                diverse clientele, including individuals, businesses, banks,
                financial institutions, and other organisations. Our lawyers are
                passionate about providing personalised and effective legal
                guidance across a broad range of practice areas.
              </p>
            </Box>
          </Box>
        </Box>
        <Box className="image-container-law">
          <img
            src={firstImage}
            alt="Foreground"
            className="firm-image-law foreground-image-law"
          />
        </Box>
      </Box>
      <Box className="bottom-section-law">
        <p>
          From real estate, banking and finance, dispute resolution, mergers and
          acquisitions, banking documentation, we help you navigate the
          complexities of the legal system and achieve your goals. We are
          committed to understanding your unique needs and delivering
          comprehensive legal solutions.
        </p>
        <p className="strong-text">Strong Partnerships, Collaborative Spirit</p>
        <p>
          MDP Associates - Advocates & Solicitors is proud to be empanelled with
          several respected institutions and banks, reflecting our commitment to
          trusted partnerships. We believe in the power of teamwork and foster a
          collaborative, friendly work environment. Our lawyers support each
          other and celebrate each other's successes, ensuring a strong and
          motivated team.
        </p>
        <p className="strong-text">Our Vision</p>
        <p>
          To be recognized as a leading full-service law firm that consistently
          delivers outstanding legal services, fostering long-term relationships
          built on trust and success.
        </p>
        <p className="strong-text">Our Mission</p>
        <p>
          To empower our clients through strategic legal counsel, fostering
          trust and collaboration to navigate complex legal challenges and
          achieve their goals.
        </p>
        <p>
          To provide comprehensive legal solutions tailored to meet the unique
          needs and challenges of each client with a deep understanding of
          Indian Laws and a focus on achieving favourable outcomes for our
          clients. With a strong foundation in integrity, expertise,
          professionalism, and client satisfaction, we strive to deliver the
          highest quality legal representation and advocacy across India.
        </p>
        <p className="strong-text">Our Values</p>
        <p className="values-list-law">
          <p>
            Unwavering Commitment to Excellence:We strive for the highest
            standards in legal practice, consistently exceeding expectations.
            <span>
              Uncompromising Ethics and Professionalism: Integrity and ethical
              conduct are paramount in everything we do.Mutual Respect and Collaboration: We believe in fostering strong
              relationships with our clients, built on trust and open
              communication.
            </span>
            <span>
              
            </span>
          </p>
        </p>
      </Box>
    </>
  );
};

export default LawFirmInfoComponent;
