import React from 'react';
import "../style/MainProfile.css";
import profileImage from '../images/Ashok-Parajpe-profile.webp';
import bestLawFirmImage from '../images/awards.png';
import yorkePrizeImage from '../images/awards.png';
import harrisonAwardImage from '../images/awards.png';
import legalAwardsImage from '../images/awards.png';
import { Button } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';

const MainProfileComponent = () => {
  const navigate = useNavigate();
  const awards = [
    { image: bestLawFirmImage, title: 'Best Law Firm' },
    { image: yorkePrizeImage, title: 'Yorke Prize' },
    { image: harrisonAwardImage, title: 'Harrison Award' },
    { image: legalAwardsImage, title: 'Legal Awards' },
  ];

  return (
    <div className='main-profile-content'>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 8, md: 4 }} spacing={2}>
        <Grid item xs={12} md sm={12} className='image-linkedin-class'>
          <div className=" profile-container-main">
            <img src={profileImage} alt="Profile" className="profile-image-main" />
            <a className="linkedin-img-icon"
                href="https://www.linkedin.com/in/ashok-paranjpe-00582912/"
                target="_blank"
                rel="noopener noreferrer">IN</a>
          </div>
        </Grid>
        <Grid item xs={12} md={6} sm={12} className='profile-details-container-main'>
          <div className="profile-container-main">
            <div className=" profile-details-main">
              <div className="profile-name-main">ASHOK PARANJPE</div>
              <div className="profile-position-main"><b>Managing Partner</b></div>
              <div className="profile-description-main">
                For two decades, Ashok was a partner at the esteemed law firm Wadia Ghandy & Co., where he honed his expertise in Real Estate. He has advised developers, individuals, and societies on property redevelopment initiatives, ensuring meticulous legal documentation. Ashok's specialization in Real Estate financing is bolstered by his qualifications as a Solicitor and his esteemed status as a senior practitioner.<br/>
               
                <p>He is registered with Indian Institute Corporate Affairs (IICA) in its Independent Directors Databank and is presently, a Non-executive Independent Director of LIC MUTUAL FUND Trustee Pvt. Ltd., and SICOM Limited.</p>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      
      <div className='bottom-profile-text'>
        <p> With nearly four decades of professional practice, Ashok Paranjpe stands as a formidable legal veteran, known for his strategic counsel and effective representation of prominent corporate entities and business organizations. His extensive experience spans across Banking and Finance, Real Estate, Corporate Laws including Capital Markets, and Arbitration and Dispute Resolution.</p>
        <p>Ashok's advocacy is not confined to a single jurisdiction; he has handled complex legal matters before the Supreme Court of India, various High Courts across India, and the Queen's Court of Justice in London. His international experience underscores his ability to navigate diverse legal landscapes with ease and proficiency.</p>
        
        <p>Frequently sought after by financial institutions, Ashok provides invaluable advice on securities transactions, cross-border lending arrangements, and the acquisition of financial assets, including distressed assets. His expertise in structuring Project Finance and Consortium Lending documentation is particularly notable in the realms of complex Real Estate and Infrastructure projects.</p>
        <p>Ashok has also played a crucial role in guiding foreign lenders on External Commercial Borrowings and has facilitated multinational banks in establishing full-scale retail banking operations in India. His contributions to the legal field have been recognized by The Asia Pacific Legal 500, which praises his versatile competence and significant impact in the Real Estate sector, particularly regarding IPOs, rights issues, and Qualified Institutional Placements (QIPs).</p>
        <div className="education-section">
          <p>Education</p>
          <ul className="education-list">
            <li>Government Law College, Mumbai</li>
            <li>Solicitor - The Bombay Incorporated Law Society</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MainProfileComponent;
