// src/components/Footer.js
import React from 'react';
import { Box, Link } from '@mui/material';
import '../style/Footer.css';

const Footer = () => {
  return (
    <Box className="footer-container">
      <Box className="footer-content">
        <Box className="footer-section">
          <h2>Disclaimer</h2>
          <p>The information provided on this website is for general informational purposes only and does not constitute legal advice. For specific legal advice, please contact our office directly.</p>
        </Box>
        {/* <Box className="footer-links">
          <Link href="/privacy-policy" className="footer-link">Privacy Policy</Link>
          <Link href="/terms-of-service" className="footer-link">Terms of Service</Link>
        </Box> */}
      </Box>
      {/* <Box className="footer-bottom">
        <p>&copy; 2024 [Your Law Firm]. All rights reserved.</p>
      </Box> */}
    </Box>
  );
};

export default Footer;
