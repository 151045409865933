import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/HeaderComponent.js';
import './style/Header.css';
import './App.css';
import CarouselComponent from './components/CarouselComponent.js';
import CarouselComponentMobile from './components/CarouselComponentMobile';
import LawFirmInfoComponent from './components/LawFirmInfoComponent.js';
import MainProfileComponent from './components/MainProfileComponent.jsx';
import TeamComponent from './components/TeamComponent.js';
import InquiryForm from './components/InquiryComponent.jsx';
import FooterComponent from './components/FooterComponent.js';
import DisclaimerPopup from './components/DisclaimerComponent.js';
import ServicesPageComponent from './components/ServicesPageComponent.jsx';

function App() {
  return (
    <Router>
      <div className="app-container">
      <DisclaimerPopup />
        <Navbar />
        <div className="main-content">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <CarouselComponent />
                  <CarouselComponentMobile/>
                 
                  <LawFirmInfoComponent />
                  <MainProfileComponent />
                  <TeamComponent />
                 
                  <ServicesPageComponent />
                  <InquiryForm />
                </>
              }
            />
            {/* <Route path="/team/:memberId" element={<TeamDetailsComponent />} />
            <Route
              path="/about-us"
              element={
                <>
                  <AboutUsComponent />
                </>
              }
            />
            <Route
              path="/contact-us"
              element={
                <>
                  <ContactUsComponent />
                </>
              }
            />
            <Route
              path="/paranjpe"
              element={
                <>
                  <ParanjpeePageComponent />
                </>
              }
            /> */}
            <Route
              path="/services"
              element={
                <>
                  <ServicesPageComponent />
                </>
              }
            />
          </Routes>
        </div>
        <FooterComponent />
      </div>
    </Router>
  );
}

export default App;
