import React from 'react';
import { Container, Box, Card, CardMedia, CardContent } from '@mui/material';
import '../style/Team.css';
import tusharImage from '../images/Tushar.webp';
import radhikaImage from '../images/Radhika.webp';
import nehaImage from '../images/Neha.webp';

const TeamComponent = () => {
  const teamMembers = [
    { id: 'tushar', name: 'Tushar Kadam', position: 'Partner', image: tusharImage, details: 'Tushar brings over ten years of experience in complex litigation across various areas. His expertise spans Real Estate (RERA), Revenue, Commercial Law, and Dispute Resolution. Tushar has a proven track record of drafting legal documents for a wide range of courts and tribunals. This includes the Bombay High Court, City Civil Court, MahaRERA Authority, RERA Appellate Tribunal, Revenue Tribunals, and Consumer Forums. His experience extends to regularly appearing before these bodies, advocating for his clients best interests.', linkedIn:"https://www.linkedin.com/in/tushar-m-kadam-892a271a/" },
    { id: 'radhika', name: 'Radhika Dixit', position: 'Partner', image: radhikaImage, details: 'With an experience of more than a decade, Radhika has handled various matters relating to litigation as well as non-litigation. Her practise areas and expertise include Real Estate, Project Finance, Testamentary matters, Arbitration and Commercial disputes. She advises clients on various matters relating to real estate transactions including conducting title due diligence for banks, financial institutions, developers, documentation relating to development and redevelopment of immovable properties, drafting and vetting of loan documents for LAP, Home Loans and Business Loans and also advises clients on sale, purchase, leasing of properties.', linkedIn:"https://www.linkedin.com/in/radhika-dixit-a232ab77/" },
    { id: 'neha', name: 'Neha Tandon', position: 'Partner', image: nehaImage, details: "Capitalising over ten years of experience, Neha Tandon, advices clients across the range of matters pertaining to real estate, corporate law, media, commercial transactions and testamentary documents. In the realm of real estate, she excels in conducting thorough title due diligence of land, residential, and commercial properties for a wide array of clients including banks, corporates, individuals, financial institutions, and developers and drafting numerous documentation for various transactions involving sale / purchase / leasing / redevelopment of immovable properties. She drafts documents pertaining to loan and mortgage, mergers, acquisitions and a variety of commercial agreements crucial for business operations. She has extended herself to provide services pertaining to corporate assignments including company incorporations and legal due diligence. Furthermore, she specializes in media transactions, ensuring comprehensive documentation for OTT series and film agreements. ", linkedIn:"https://www.linkedin.com/in/neha-tandon-264598a3/" },
  ];

  return (
    <Container className="team-container" id="our-team">
      <Box className="team-title">
        <div className="diamond-2"></div>MEET OUR EXPERT LEGAL TEAM<div className="diamond-2"></div>
      </Box>
      <Box className="team-subtitle">VISION MAKES US WHO WE ARE</Box>
      <Box className="team-members">
        {teamMembers.map((member) => (
          <Card
            className="team-member"
            key={member.id}
            sx={{ maxWidth: 300, margin: 2 }}
          >
            <Box position="relative">
              <CardMedia
                component="img"
                className='experts-card'
                height="300"
                image={member.image}
                alt={member.name}
              />
              <a
                className="linkedin-icon"
                href={member.linkedIn}
                target="_blank"
                rel="noopener noreferrer"
              >
                IN
              </a>
            </Box>
            <CardContent className="expert-team-cards">
              <Box>
                <h3 className='team-member-name'><b>{member.name}</b></h3>
                <p className='team-member-position'><b>{member.position}</b></p>
              </Box>
            </CardContent>
            <CardContent>
              <p>{member.details}</p>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Container>
  );
};

export default TeamComponent;
