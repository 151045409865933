import React, { useState } from "react";
import "../style/Header.css";
import mdp_logo_01 from "../images/MDP-LOGO.webp";

const HeaderComponent = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const scrollToElement = (id, event) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      const offsetElement = document.querySelector(".header");
      const offset = offsetElement ? offsetElement.offsetHeight : 5;
      const extraMargin = 10;
      const elementPosition = element.offsetTop - offset - extraMargin;
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="fixed-header">
      <header className="header">
        <div className="logo-left">
          <img src={mdp_logo_01} alt="MDP Logo" className="logoImage" />
        </div>
        <div className="content-header">
          <div className="top-content">
            <div className="contact-info-header">
              {/* Add contact information here if needed */}
            </div>
          </div>
          <div className="bottom-content">
            <nav className={`menu ${menuOpen ? "open" : ""}`}>
              <a onClick={(e) => scrollToElement("home", e)} href="#home">
                <div className="diamond"></div> HOME
              </a>
              <a onClick={(e) => scrollToElement("about-us", e)} href="#about-us">
                <div className="diamond"></div> ABOUT US
              </a>
              <a onClick={(e) => scrollToElement("our-team", e)} href="#our-team">
                <div className="diamond"></div> OUR TEAM
              </a>
              <a onClick={(e) => scrollToElement("services", e)} href="#services">
                <div className="diamond"></div> SERVICES
              </a>
              <a onClick={(e) => scrollToElement("contact-us", e)} href="#contact-us">
                <div className="diamond"></div> CONTACT US
              </a>
            </nav>
            <div className="hamburger" onClick={toggleMenu}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default HeaderComponent;
