import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Paper } from '@mui/material';
import '../style/ServicePageComponent.css';

const ServicesPageComponent = () => {
  const [activeSection, setActiveSection] = useState('litigationAndDisputeResolution');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [manualSelection, setManualSelection] = useState(false);

  const services = [
    {
      title: 'REAL ESTATE EXPERTISE',
      content: (
        <>
          <p>From foreign direct investment to title certification, legal due diligence and documentation,
            redevelopment projects with builders/societies/members, and various property transactions
            including acquisition of shareholding in real estate companies by global asset management funds.
            Guiding you at every stage of purchase or sale of immovable property, including flats, offices and
            commercial premises.</p>
          <p><strong className='services-heading'>RE-DEVELOPMENT WORK: BUILDERS/ SOCIETIES/ MEMBERS</strong></p>
          <ul>
            <li>Advising and documentation between CRD Realtors Private Limited and Ten X Realty Limited
              (subsidiary of Raymond Limited), with relation to development of 10 society buildings constructed
              by MHADA in Nirmal Nagar Layout Bandra (West) admeasuring approx. 6142.87 sq. mtrs and
              currently housing 408 families.</li>
            <li>Advising and documentation in respect of development of 18 buildings of Sahakar Nagar 1 Society
              and comprising of 228 flats, constructed by MHADA, situated in Shell Colony, Chembur, which
              project is now known as “GODREJ PRIME”.</li>
            <li>Advising various builders, individuals, Societies and its Members in connection with re-development
              of the Society buildings, and finalizing all legal documentation in connection therewith, consisting of
              inter alia all necessary resolutions, execution of development Agreement, Power of Attorney and
              other necessary documents.</li>
          </ul>
          <p><strong>ACQUISITION OF SHAREHOLDING OF SUPREME INFRASTRUCTURE AND ITS RELATED ENTITIES BY A
            GLOBAL ASSET MANAGEMENT FUND</strong></p>
          <ul>
            <li>Advising and documentation with respect to acquisition of shareholding in Special Purpose Vehicle
              (SPV) companies formed for this purpose.</li>
            <li>Drafting/Vetting of various documents such as Share Subscription Agreement, Shareholders
              Agreement, Debenture Trust Deed, Share Pledge Agreement.</li>
            <li>Documentation for development of Residential Zone comprising of Sale Component and Rehab
              Component under Slum Rehabilitation Scheme of Mumbai Metropolitan Regional Development
              Authority (MMRDA) including PAP Construction Agreement, Agreement for Sale of Sale Component,
              Power of Attorney, Addendum to Multipartite Agreement executed with MMRDA, Project
              Management Agreement, Development Management Agreement etc.</li>
          </ul>
          <p><strong className='services-heading'>REAL ESTATE- LEGAL DUE DILIGENCE AND DOCUMENTATION</strong></p>
          <ul>
            <li><strong>HDFC LIMITED (HDFC):</strong> Recently handled an assignment wherein HDFC has divested various
              flats/apartments owned by them, situated over 13 locations across various cities in India, through a
              bidding process and drafting of Deeds of Transfer/Sale Deeds/Deeds of Apartment for transfer of
              flats/apartments in favour of the prospective purchasers.</li>
            <li><strong>NATIONAL STOCK EXCHANGE OF INDIA LIMITED (NSEIL):</strong> Presently handling an assignment wherein
              NSEIL is divesting 17 flats/apartments owned by them and providing assistance for title certification,
              drafting of documentation in respect thereof in favour of prospective purchasers and generally
              advising them in this regard.</li>
            <li><strong>THE INDIAN HOTELS COMPANY LTD (TAJ HOTELS/ IHCL):</strong> Recently handled an assignment wherein
              TAJ HOTELS were divesting more than 100 flats/apartments owned by them, situated in Mumbai,
              Delhi &amp; Kolkata, through a bid process and provided end-to-end assistance including investigation of
              title of IHCL to the flats/apartments. Assisted in entire documentation including drafting of bid
              documents, deeds of transfer/apartment and all other miscellaneous documents.</li>
            <li><strong>STANDARD CHARTERED BANK:</strong> Advised in drafting documents for acquiring their Office premises in
              CRESCENZO, BKC and advising in formation of Condominium under the Maharashtra Apartment
              Ownership Act, 1970. Relocation of the branches of the Bank across various cities in India: Carrying
              out title due diligence of properties to be taken on lease by the Bank, drafting and negotiating lease
              deeds and allied documents on behalf of the Bank and liaising and co-ordinating extensively with
              concerned parties for ensuring timely relocation.</li>
            {/* <li><strong>JM FINANCIAL HOME LOANS LIMITED:</strong> Title due diligence and vetting of original title documents for various properties/projects at Mumbai, Thane, Kalyan, Navi Mumbai, Panvel, Thane District, Palghar District, Raigad District – in all areas largely covered under the Mumbai Metropolitan Region (MMR).</li> */}
            <li><strong>NIRMAL LIFESTYLE LTD.:</strong> Advised on and revised the standard Agreement for Sale in terms of the
              Real Estate (Regulation and Development) Act, 2016. Advised on acquisition and development of
              various properties, agricultural and non-agricultural lands in various States, including development
              rights. Advised on setting up of Nirmal Lifestyle Mall, Mulund, a landmark commercial premises
              belonging to Nirmal Group and almost all their real estate development projects including
              documentation for setting up India&#39;s first Shoprite Store. Advising on setting up of Lifestyle City
              presently under construction at Kalyan spread over 400 acres for setting up a Township under the
              new guidelines issued by the state of Maharashtra.</li>
            <li><strong>DBS BANK:</strong> Title due diligence and legal opinion for various land parcels
              across India.</li>
            {/* <li><strong>RBL BANK LIMITED:</strong> Title due diligence and legal opinion for various land parcels in Mumbai, Goa, Telangana.</li>
            <li><strong>AJMERA REALTY & INFRA LIMITED:</strong> Advised Ajmera Housing in matters relating to acquisition of properties and represented them in property related litigation matters.</li>
            <li><strong>EVEREST DEVELOPERS:</strong> Advised Everest Group and a major Venture Capital Fund on complex structured transactions, concerning joint development of properties, assisted in legal documentation and represented Everest Group in litigation matters.</li>
            <li><strong>SAHANA GROUP & TRANSSTADIA:</strong> Advice and structuring, recently concluded assignments for Secured Lenders to Sahana Group & TransStadia. Project finance and consortium lending documentation for real estate and infrastructure projects.</li> */}
            <li><strong>UNITY INFRAPROJECTS LIMITED AND GROUP COMPANIES:</strong> Advising and drafting various documents
              in relation to a proposed Hotel Project in Nagpur. Rendering legal advice in connection with a
              proposed Joint Venture with a Spanish Company having experience in infrastructure projects.
              Advising on documentation in connection with Concession Agreement of a Waste Management
              Facility, Construction of National Highways on BOT basis, Construction Contracts and others.</li>
          </ul>
        </>
      ),
    },
    {
      title: 'LITIGATION AND DISPUTE RESOLUTION',
      content: (
        <>
          <p>Navigate complex legal challenges through arbitration and litigation, specializing in commercial disputes, real estate issues, and banking & finance matters across all forums including Supreme Court of India, High Courts, Domestic and Foreign Arbitrations, National Company Law Tribunal (NCLT), National Company Law Appellate Tribunal (NCLAT), Real Estate Regulation Authority (RERA), Real Estate Regulation Appellate Authority, Consumer Forums across the country, etc.</p>
          <p><strong className='services-heading'>TATA CAPITAL LIMITED (TATA CAPITAL FINANCIAL SERVICES LIMITED):</strong> Representing Tata Capital Limited (now known as Tata Capital Financial Services Limited) in litigation matters for recovery of loans before the High Court, Mumbai, SUPREME COURT OF INDIA and Arbitration Proceedings in the High Court & before the Arbitrator, including winding up of Borrower Companies and enforcement proceedings.</p>
          <p><strong className='services-heading'>L&T INFRASTRUCTURE FINANCE COMPANY LIMITED/ L&T FINANCE LIMITED:</strong> Representing L & T Infra in Arbitration proceedings in the High Court & before Arbitrator, before the Debt Recovery Tribunal (DRT) and Advising in settlement of Disputes. Representing L&T Finance before the High Court Mumbai in dispute relating to refusal to grant Registration from Central Excise Department and successfully obtaining order for issuance of such License.</p>
          <p><strong className='services-heading'>BARCLAYS BANK PLC:</strong> Representing the Bank’s India and Dubai branches in the High court and before the Debt Recovery Tribunal (DRT) in recovery proceedings.</p>
          <p><strong className='services-heading'>COMMERZBANK:</strong> Conducting litigation in Queen's Court (London, England) on behalf of Commerzbank, Singapore and six other offshore lenders against a large Indian Corporate for recovery of loans, wherein one of the largest private Bank was joined as one of the defendants alleging breach of trustee obligations. This litigation was settled and payments were received to the satisfaction of Lender Banks.</p>
          <p><strong className='services-heading'>AXIS BANK AND SHRIRAM FINANCE:</strong> Representing the Bank and NBFC in High Court, NCLT and other forums.</p>
          <p><strong className='services-heading'>OTHER LITIGATION:</strong> Handled litigations earlier in Bombay High Court & later in DRT, DRAT, and SARFEASI Act for recovery of dues on behalf of various lenders, banks & institutions. Representing Nationalized, Private, and Scheduled Banks like BANK OF INDIA, BANK OF MAHARASHTRA, BANK OF BARODA, BNP PARIBAS, CREDIT AGRICOLE, STANDARD CHARTERED BANK, DBS BANK, etc. NBFC’s like 20th Century Finance Corporation Ltd., Alpic Finance, Tata Finance, etc, wherein certain landmark judgments have been passed under the Sick Industrial Companies Act, 1985, since it was a new legislation at that time. Litigations on behalf of a minority shareholder relating to the Company under sections 397, 398 & 401 of Companies Act, 1956, for oppression and mismanagement proceedings in Bombay High Court and Company Law Board. Advising clients on proceedings in NCLT & NCLAT & handling matters under the newly enacted Insolvency & Bankruptcy Code.</p>
        </>
      ),
    },
    {
      title: 'CORPORATE AND FINANCIAL TRANSACTIONS',
      content: (
        <>
          <p>We offer a comprehensive toolbox for your financial needs, including asset finance, traditional lending, project finance, home finance, vehicle finance, and real estate finance.</p>
          <p><strong className='services-heading'>RBL BANK LIMITED:</strong> Drafted the entire set of loan and security documents including Sanction Letter, Term Loan/Overdraft Agreement, Demand Promissory Note, Memorandum of Deposit of Title Deeds, Declarations, Power of Attorney, Deed of Guarantee, Deed of Simple Mortgage, Supplemental Deed of Simple Mortgage, Application Form, Board Resolution for Company, LLP Resolution, Partnership Firm Letter of Authority for various products such as business instalment loans, small business secured loan, term loan and overdraft against property, etc.</p>
          <p><strong className='services-heading'>SHIPPING FINANCE:</strong> Carried out research on maritime law, including the procedure for purchase and registration of ships in India and creation of a valid enforceable mortgage on the same. Drafted, advised on structuring and finalization of the Term Loan Agreement, Hypothecation Deed and Mortgage Deeds, perfection of the mortgage and filing requirements with relevant Indian authorities.</p>
          <p><strong className='services-heading'>PREPAID PAYMENT INSTRUMENTS AND RELATED SERVICES:</strong> Drafted and advised on various agreements such as Mobile POS services Agreement, Payment App Service Provider Agreement.</p>
          <p><strong className='services-heading'>NIRMAL LIFESTYLE LIMITED AND ITS GROUP COMPANIES:</strong> Advised, drafted, modified, negotiated and finalized all finance documents (including inter alia the Debenture Trust Deed, Debenture Subscription Agreement etc) in respect of financial assistance obtained by the Nirmal Group, including transactions with Altico Capital India Private Limited, Piramal Enterprises Ltd. and HDFC Limited, ILFS, Kotak Mahindra, and STCI.</p>
          <p><strong className='services-heading'>TATA CAPITAL FINANCIAL SERVICES LIMITED:</strong> Reviewed the Facility and Security Documents of 60 loan accounts, drafted and issued Reports on the enforceability of the facility documents executed between the NBFC and its customers.</p>
          <p><strong className='services-heading'>CAPITAL FIRST LIMITED:</strong> Reviewed the Facility Documents in relation to the wholesale lending portfolio to ascertain that the documents are valid and enforceable.</p>
          <p><strong className='services-heading'>STRUGENCE INVESTMENT ADVISORS LLP:</strong> Advised in relation to investment in non-convertible debentures (NCDs) issued by a real estate developer. Conducted title due diligence, drafted and negotiated all transaction documents for the issue of NCDs on a private placement basis.</p>
          <p><strong className='services-heading'>MODELLA TEXTILE INDUSTRIES LIMITED:</strong> Vetted the Transaction Documents executed between ECL Finance Limited and Modella Textile Industries Limited, including the Term Sheet, Indenture of Mortgage, Debenture Trust Deed, etc.</p>
          <p><strong className='services-heading'>MANAPPURAM HOME FINANCE PRIVATE LIMITED:</strong> Advised and prepared the template home loan agreement and Memorandum of Deposit of Title Deeds forming part of MHFPL’s loan portfolio.</p>
          <p><strong className='services-heading'>SAHANA GROUP AND TRANSSTADIA:</strong> Advised and structured the assignments for Secured Lenders to Sahana Group & TransStadia. Handled the project finance and consortium lending documentation for the real estate and infrastructure projects.</p>
          <p><strong className='services-heading'>CAPRI GLOBAL ADVISORY SERVICES PRIVATE LIMITED:</strong> Drafted and finalized the Loan Agreement, Indentures of Mortgage, Deed of Pledge, Deed of Hypothecation, Cheque Deposit Letter, Demand Promissory Note, Letter of Continuity and other Security Documents to be executed between the parties in respect of the loan to be given to the borrowers.</p>

          <p> <strong className='services-heading'>CORPORATE & COMMERCIAL TRANSACTIONS:</strong> Support your business ventures with expertise in joint ventures, mergers & acquisitions, business transfers, private equity & venture capital transactions, and commercial agreements.</p>
          <ul>
            <li>
              <p><strong className='services-heading'>CREDIT ANALYSIS AND RESEARCH LIMITED (CARE):</strong></p>
              <ul>
                <li>Advice in documentation in relation to a proposed Joint Venture Agreement with a company in Nepal for setting up a Credit Rating Company.</li>
              </ul>
            </li>
          </ul>

        </>
      ),
    },
    {
      title: 'OTHER SERVICES',
      content: (
        <>
          <ul>
            <li>
              <p><strong>Estate Planning & Wealth Preservation:</strong> advising individuals and families with estate planning, wills, codicils, and trust creation to ensure your legacy is protected. We also assist with probate matters, succession certificates, and letters of administration
              </p>
            </li>
            <li>
              <p> <strong> Private Client Services:</strong> Catering to your individual needs beyond estate planning and providing personalized legal guidance on various matters
              </p>
            </li>
          </ul>
        </>
      ),
    }
  ];


  const serviceKeys = services.map((service) => service.title);

  // useEffect(() => {
  //   if (!manualSelection) {
  //     const interval = setInterval(() => {
  //       setCurrentIndex((prevIndex) => (prevIndex + 1) % serviceKeys.length);
  //     }, 5000); // Change section every 5 seconds

  //     return () => clearInterval(interval);
  //   }
  // }, [manualSelection, serviceKeys.length]);

  useEffect(() => {
    setActiveSection(serviceKeys[currentIndex]);
  }, [currentIndex, serviceKeys]);

  const handleSectionClick = (key, index) => {
    setActiveSection(key);
    setCurrentIndex(index);
    setManualSelection(true); // Stop the automatic rotation when a section is manually selected
  };

  return (
    <Grid className="services-container" id="services">
      <h1>SERVICES</h1>
      <p className='service-subtitle'>VISION MAKES US WHO WE ARE</p>
      <Box className="servicespage">
        <Box className="servicespage-tabs">
          {serviceKeys.map((key, index) => (
            <Box
              key={key}
              className={`servicespage-tab ${activeSection === key ? 'active' : ''}`}
              onClick={() => handleSectionClick(key, index)}
            >
              {key}
            </Box>
          ))}
        </Box>
        <Paper elevation={0} className="servicespage-content">
          {services.find(service => service.title === activeSection)?.content}
        </Paper>
      </Box>
    </Grid>
  );
};

export default ServicesPageComponent;
