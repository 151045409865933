import React, { useState, useEffect } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Modal, Paper } from '@mui/material';

const DisclaimerPopup = () => {
  const [open, setOpen] = useState(false);
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    if (!sessionStorage.getItem('disclaimerAccepted')) {
      setOpen(true);
    }
  }, []);

  const handleAcceptChange = (event) => {
    setAccepted(event.target.checked);
  };

  const handleProceed = () => {
    sessionStorage.setItem('disclaimerAccepted', 'true');
    setOpen(false);
  };

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <Paper
          sx={{
            padding: 3,
            maxWidth: 600,
            width: '90%',
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px', color: '#007bff' }}>
            Disclaimer
          </div>
          <div style={{ marginBottom: '20px' }}>
            The Bar Council of India does not permit advertisement or solicitation by advocates in any form or manner. By accessing this website, <strong>www.mdplawassociates.com</strong>, you acknowledge and confirm that the information provided on this website is solely available at the request of the user(s) for informational purposes only and should not be interpreted as a solicitation or advertisement.
            <br />
            <br />
            Any information obtained or downloaded by the user from our website does not lead to the creation of the client – attorney relationship between the Firm and the user.
          </div>
          <FormControlLabel
            control={<Checkbox checked={accepted} onChange={handleAcceptChange} />}
            label="I accept the above."
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleProceed}
              disabled={!accepted}
            >
              PROCEED TO WEBSITE
            </Button>
          </div>
        </Paper>
      </Box>
    </Modal>
  );
};

export default DisclaimerPopup;
