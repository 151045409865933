import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "../style/Carousel.css";
import profileImage from '../images/About-Ashok.jpg';

import firstImage from "../images/MDP-home-1.webp";
import firstImageMobile from "../images/Ashok Parajpe profile Mobile version.webp";
import secondImage from "../images/MDP-home-banner.webp";

const CarouselComponent = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 320);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    arrows: false,
    fade: true,
    customPaging: (i) => (
      <div className="custom-dot">
        <span>♦</span>
      </div>
    ),
    dotsClass: "slick-dots custom-dots",
  };

  return (
    <Slider {...settings} className="slider-desktop">
      <div className="carousel-slide" id="home">
        <img src={firstImage} alt="Slide 1" className="carousel-image" />
        <div className="carousel-caption">
          <h2>
            MDP ASSOCIATES
            {isSmallScreen && <span className="subtitle">Advocates & Solicitors</span>}
            {!isSmallScreen && " | Advocates & Solicitors"}
          </h2>
          <h1>
            LEGACY OF
            {isSmallScreen && <span className="highlight">EXCELLENCE</span>}
            {!isSmallScreen && " EXCELLENCE"}
          </h1>
          {/* <div className="carousel-buttons">
            <a href="#contact" className="carousel-button">
              CONTACT US
            </a>
          </div> */}
        </div>
      </div>
      <div className="carousel-slide">
        <img src={secondImage} alt="Slide 2" className="carousel-image" />
        <div className="carousel-caption">
          <h2 className="carousel-banner-2-heading">
            MDP ASSOCIATES
            {isSmallScreen && <span className="subtitle">Advocates & Solicitors</span>}
            {!isSmallScreen && " | Advocates & Solicitors"}
          </h2>
          <p className="banner-2-content" style={{
            fontSize: "27px",
            lineHeight: "1.25"
          }}>
            Empowering You Through Legal Challenges. Delivering Exceptional
            Results and Client-Focused Solutions.
          </p>
          {/* <div className="carousel-buttons">
            <a href="#contact" className="carousel-button">
              CONTACT US
            </a>
          </div> */}
        </div>
      </div>
    </Slider>
  );
};

export default CarouselComponent;
