import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import emailjs from '@emailjs/browser';
import "../style/InquiryForm.css";

const InquiryForm = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  // Validation function
  const validate = () => {
    const errors = {};
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const messageRegex = /^[A-Za-z0-9\s.,!?'"()-]*$/;

    if (!nameRegex.test(fullName) || fullName.trim() === "") {
      errors.fullName = "Please enter a valid name.";
    }

    if (!emailRegex.test(email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (message.length > 300 || !messageRegex.test(message)) {
      errors.message = "Message should be less than 300 characters and contain only valid characters.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "fullName") {
      setFullName(value.replace(/[^A-Za-z\s]/g, ""));
    } else if (name === "email") {
      setEmail(value.replace(/[^a-zA-Z0-9._%+-@]/g, ""));
    } else if (name === "message") {
      setMessage(value.slice(0, 300).replace(/[^A-Za-z0-9\s.,!?'"()-]/g, ""));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!validate()) {
      setIsSubmitting(false);
      return;
    }

    // fetch("https://api.shvx.in/api/v1/lead/create", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer oWVZwNmi4ppy",
    //     // Authorization: "Bearer 8AQiGl2Gccc3",
    //   },
    //   body: JSON.stringify({
    //     fullName,
    //     email,
    //     message,
    //   }),
    // })

    emailjs
      .send(
        'service_cnro2oi',     // Service ID from EmailJS
        
        'template_oghq80e', // Template ID from EmailJS
        {
          from_name: fullName,
          email_id: email,
          message: message
        },
        'toKduHkcQZftBmazq'          // Public User ID from EmailJS
      )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setShowSuccess(true);
          setFullName("");
          setEmail("");
          setMessage("");
        } else {
          console.log("Submission failed");
        }
        setIsSubmitting(false);
      })
      .catch(() => {
        console.log("Submission failed");
        setIsSubmitting(false);
      });
  };

  // Close success message
  const handleCloseSuccess = () => {
    setShowSuccess(false);
  };

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 8, md: 12 }}
      spacing={2}
      id="contact-us"
    >
      <Grid item xs={10} md sm={11}>
        <div className="inquiry-form-container">
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d235.87836652050862!2d72.833458!3d18.9290098!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7d10056ff3637%3A0x1c4742bb000fbd75!2sMDP%20Associates%20Advocates%20%26%20Solicitors%2C%201st%20Floor%2C%20Bandukwala%20Building%2C%20Kala%20Ghoda%2C%20Fort!5e0!3m2!1sen!2sin!4v1723034633280!5m2!1sen!2sin"
              title="map"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          {/* Address  */}
          <div className="address-contact-info">
            <div className="client-details">
              <p> Address: </p>&nbsp;
              <a
                href="https://www.google.com/maps/search/?api=1&query=Bandukwala+building,+1st+floor,+British+Hotel+Lane,+Kala+Ghoda,+Fort,+Mumbai,+400001"
                target="_blank"
                className="client-details-address"
                rel="noopener noreferrer"
              >
                Bandukwala building, 1st floor, British Hotel Lane, Kala
                Ghoda, Fort, Mumbai, 400001
              </a>
            </div>
            <div className="client-details">
              <p>Phone: </p>&nbsp;
              <a href="tel:022-22677235">022-22677235</a>
            </div>
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sm={12}
        className="profile-details-container-main"
      >
        <Box className="form">
          <Box className="inquiry-header">
            <h1>MAKE AN INQUIRY?</h1>
            <p className="inquiry-form-subtitle">
              VISION MAKES US WHO WE ARE
            </p>
          </Box>
          <Box className="form">
            <Box className="form-row">
              <TextField
                label="Full Name"
                variant="outlined"
                className="text-field half-width"
                InputLabelProps={{ className: "text-field-label" }}
                InputProps={{
                  className: "text-field-input",
                  placeholder: "Enter your full name",
                }}
                value={fullName}
                onChange={handleChange}
                error={!!errors.fullName}
                helperText={errors.fullName}
                name="fullName"
              />
              <TextField
                label="E-mail"
                variant="outlined"
                className="text-field half-width"
                InputLabelProps={{ className: "text-field-label" }}
                InputProps={{
                  className: "text-field-input",
                  placeholder: "Enter your email",
                }}
                value={email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                name="email"
              />
            </Box>
            <TextField
              label="Type here..."
              variant="outlined"
              multiline
              rows={4}
              className="text-field full-width"
              InputLabelProps={{ className: "text-field-label" }}
              InputProps={{
                className: "text-field-input",
                placeholder: "Enter your message",
              }}
              value={message}
              onChange={handleChange}
              error={!!errors.message}
              helperText={errors.message}
              name="message"
            />
            <Button
              variant="contained"
              className="submit-button inquiry-submit-btn"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              SEND REQUEST
            </Button>

            {/* Success Message */}
            {showSuccess && (
              <Box mt={2} className="success-message">
                <p>Thank you for your message.We will get back to you shortly.</p>
                <button className="close-message-btn" onClick={handleCloseSuccess}>
                  OK
                </button>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default InquiryForm;
